<template>

<div id="app">    
  <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbartest.title }}</strong>
          </div>
          <div>{{ snackbartest.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>


   <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
    <template v-slot:top>
       <v-toolbar
         flat
      >
    <v-toolbar-title>LISTE DES PAYS</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="primary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
                  <v-row>
                    <v-col
                    lg="4">
                   <v-text-field
                      v-model="code_pays"
                      label="Code *"
                      dense
                      outlined>
                    </v-text-field>
                   </v-col>

                    <v-col>
                     <v-text-field
                      v-model="libelle"
                      label="Libellé *"
                      dense
                      outlined
                     
                    ></v-text-field>
                    </v-col>

                  </v-row>
                   
                     <v-file-input
                      v-model="fichier_json"
                      label="fichier geojson"
                      dense
                      outlined
                     
                    ></v-file-input>

                   
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.odd`]="{ item }">
       {{ item.code }} - {{ item.libelle }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
      color="teal"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="red"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="Actulisation"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>

</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
  data:() =>({
      multiLine: true,
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      snackbartest:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      textetat:false,
          headers: [
        { text: 'ID', value: 'id' ,divider: true,width: '5%'},
        { text: 'Code Pays', value: 'code_pays' ,divider: true,width: '25%'},
        { text: 'libelle Pays', value: 'libelle' ,divider: true,width: '60%'},
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '10%'},
      ],
        list:[],
         libelle:'',
         code_pays:'',
         fichier_json: null,
         id:'0',
        
         textetat:false,
         formTitle:"Formulaire d'enregistrement",

  }),
     methods: {
       //=====Afficher tout=======
       
      readAll: async function() {
       const data = await api.readAll('parametre/all-pays');
      this.list = data.list;
      },
     
   createUpdatedata: async function() {
      
      let fd=new FormData();
      //this.id_odds=this.selectodd;
      fd.append('libelle',this.libelle);
      fd.append('code_pays',this.code_pays);
      fd.append('fichier_json',this.fichier_json);
      fd.append('adresse',this.adresse);
      
      
   if(this.textetat){
      console.log(this.id);
     const res=await api.createUpdatedata('parametre/update-pays/'+this.id,fd);
     if(res.status==200){
        this.textmessage=res.message;
                  this.snackbartest = {
                      color: "green",
                      icon: "mdi-check-all",
                      mode: "multi-line",
                      position: "top",
                      timeout: 2500,
                      title: "Information",
                      text: this.textmessage,
                      visible: true
                    };
         this.dialog=false;
      }else{
        this.textmessage=res.message;
                  this.snackbartest = {
                      color: "green",
                      icon: "mdi-check-all",
                      mode: "multi-line",
                      position: "top",
                      timeout: 2500,
                      title: "Information",
                      text: this.textmessage,
                      visible: true
                    };
        
      } 
       
    }else{
        const res=await api.createUpdatedata('parametre/add-pays',fd);    
        if(res.status==200){
            this.textmessage=res.message;
                  this.snackbartest = {
                      color: "green",
                      icon: "mdi-check-all",
                      mode: "multi-line",
                      position: "top",
                      timeout: 2500,
                      title: "Information",
                      text: this.textmessage,
                      visible: true
                    };
                   
        }else{
              this.textmessage=res.message;
                  this.snackbartest = {
                      color: "red",
                      icon: "mdi-alert",
                      mode: "multi-line",
                      position: "top",
                      timeout: 2500,
                      title: "Erreur",
                      text: this.textmessage,
                      visible: true
                    };
       
        }
         
      }
        
        this.clear();       
        this.readAll();
      
    },
     delete: async function() {
       console.log(this.id);
     let fd=new FormData();
      fd.append('id',this.id);
       const res=await api.createUpdatedata('parametre/delete',fd);   
      
     this.readAll();
    this.dialogDelete=false;
    },
    
    supdata(){
      this.delete();
      
    }, 
    save(){
      if(this.code_pays==''||this.libelle==''){
         this.textmessage="Veuillez remplir les champs obligatoires";
                  this.snackbartest = {
                      color: "red",
                      icon: "mdi-alert",
                      mode: "multi-line",
                      position: "top",
                      timeout: 2500,
                      title: "Erreur",
                      text: this.textmessage,
                      visible: true
                    };
        return;
      }
      this.createUpdatedata();
      
    },
    nouveau(){
      
     this.clear();
     this.dialog=true;
    },
    editItem(item){
      this.textetat=true;
      this.id=item.id;
         this.libelle=item.libelle;
         this.code_pays=item.code_pays;
         this.dialog=true;
    },

    deleteItem(item){
      console.log(item.id);
       this.id=item.id;
       this.dialogDelete=true;
      
    },

    clear(){
         this.libelle='';
         this.code_pays='';
         this.textetat=false; 
    }

    },
   
   mounted() {
    this.readAll();
    
  },


}
</script>


<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>

